import 'systemjs-webpack-interop/auto-public-path/auto-public-path'
import { h, createApp } from 'vue'
import singleSpaVue from 'single-spa-vue'
import ElementPlus from 'element-plus'
import ptBr from 'element-plus/es/locale/lang/pt-br'
import 'element-plus/theme-chalk/src/index.scss'
import 'element-plus/theme-chalk/el-notification.css'
import 'element-plus/theme-chalk/el-message.css'
import 'element-plus/theme-chalk/el-message-box.css'
import '@/main.scss'
import '@/styles/notification.scss'

import App from './App.vue'
import pinia from './stores'
import i18n from './locales'
import { vMaska } from 'maska'
import vSanitize from './directives/sanitize'

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render () {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
      })
    }
  },
  handleInstance (app) {
    app
      .use(pinia)
      .use(i18n)
      .use(ElementPlus, {
        locale: ptBr
      })
      .directive('maska', vMaska)
      .directive('sanitize', vSanitize)
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
export const update = vueLifecycles.update
