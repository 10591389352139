<template>
  <el-config-provider namespace="pmanage">
    <ManagementBase class="portal-management__app" />
  </el-config-provider>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

const ManagementBase = defineAsyncComponent(async () => await import('./views/management/ManagementBase.vue'))
</script>

<style lang="scss">
</style>
