import { Directive } from 'vue'
import sanitize from 'sanitize-html'

/**
 * Diretiva personalizada para sanitizar HTML
 * Uso: v-sanitize="htmlContent"
 */
export const vSanitize: Directive = {
  mounted (el, binding) {
    if (typeof binding.value === 'string') {
      // O Vue3Sanitize.getDefaults() retorna as opções, não a função sanitizadora
      // Precisamos acessar a função diretamente
      const sanitizedHtml = sanitize(binding.value, {
        allowedAttributes: false
      })
      el.innerHTML = sanitizedHtml
    } else if (binding.value === null || binding.value === undefined) {
      el.innerHTML = ''
    }
  },
  updated (el, binding) {
    if (typeof binding.value === 'string') {
      const sanitizedHtml = sanitize(binding.value, {
        allowedAttributes: false
      })
      el.innerHTML = sanitizedHtml
    } else if (binding.value === null || binding.value === undefined) {
      el.innerHTML = ''
    }
  }
}

export default vSanitize
