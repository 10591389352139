const ptBr = {
  message: 'mensagem',
  test: 'teste',
  isTyping: 'está digitando',
  canceled: {
    reason: {
      UNQUALIFIED_TO_NEGOTIATION: 'Inapto para negociação',
      CLOSED_BY_THIRD: 'Acordo fechado por terceiros',
      DROPPED: 'Arquivado/Baixa definitiva',
      'IMPROPER_JUDGMENT/EXTINCTION': 'Sentenca Improcedente/Extinção',
      JUDICIALIZED: 'Judicializado'
    }
  },
  dispute: {
    notification: {
      'will-restart': 'A disputa será reiniciada.',
      'will-not-restart': 'A disputa não será reiniciada.',
      restarted: 'Disputa reiniciada com sucesso.',
      'will-engage': 'A disputa será reengajada.',
      'will-not-engage': 'A disputa não será reengajada.',
      engaged: 'Disputa reengajada com sucesso.'
    },
    overview: {
      confirm: {
        restart: {
          engagement: {
            question: 'Novos dados de contato foram adicionados. Deseja reiniciar a disputa?',
            confirm: 'Não reiniciar',
            cancel: 'Reiniciar disputa'
          }
        }
      },
      label: {
        'contact-found': '{count} contato encontado | {count} contatos encontrados',
        'in-the-attachments': 'nos anexos'
      }
    },
    occurrence: {
      attachment: {
        type: {
          ZIP: 'um arquivo compactado',
          IMAGE: 'uma imagem',
          PDF: 'um PDF',
          DOC: 'um documento Word',
          XLS: 'uma planilha',
          OUTRO: 'um arquivo'
        }
      }
    },
    courtHearingDate: 'Data da audiência',
    moralDamage: 'valor dano moral',
    negotiatorEmail: 'email do negociador',
    revisorEmail: 'email do revisor',
    mappedStrategy: 'nome da estratégia',
    mappedProposalValue: 'valor da primeira proposta',
    mappedCampaign: 'nome da campanha',
    mappedNote: 'notas da disputa',
    mappedLinks: 'links de documentos externos',
    mappedLinkNames: 'nomes de documentos externos',
    code: 'nº do processo',
    expirationDate: 'fim da negociação',
    materialDamage: 'valor dano material',
    externalId: 'código interno da disputa',
    description: 'descrição',
    provisionedValue: 'valor provisionado',
    status: {
      ACCEPTED: 'aceita | aceitas',
      CHECKOUT: 'sem dados bancarios',
      ENGAGEMENT: 'sem resposta da parte',
      ENRICHED: 'enriquecida | enriquecidas',
      EXPIRED: 'expirada | expiradas',
      IMPORTED: 'importada | importadas',
      PENDING: 'pendente | pendentes',
      REFUSED: 'com proposta recusada',
      RUNNING: 'em negociação',
      SETTLED: 'ganha | ganahs',
      UNSETTLED: 'perdida | perdidas',
      PRE_NEGOTIATION: 'pre-negociação'
    },
    export: {
      FAILED: 'falha',
      FINISHED: 'finalizado',
      PROCESSING: 'processando',
      QUEUE: 'aguardando'
    },
    labels: {
      note: 'Notas da conclusão (opcional)'
    }
  },
  triggers: {
    ENGAGEMENT: 'engajamento',
    UNSETTLED: 'perdido'
  },
  transactions: {
    LAWSUIT_DROPPED: 'disputa cancelada',
    DISPUTE_ACCEPTED: 'disputa aceita',
    IMPORTED_DISPUTE: 'disputa importada',
    INTERACTION: 'interação na disputa',
    MANUAL: 'valor manual',
    SETTLED_DISPUTE: 'acordo fechado',
    SUBSCRIPTION: 'assinatura',
    'PRE-NREGOTIATION': 'pré negociação'
  },
  prescription: {
    ONLY_SMS_ENGAGEMENT: 'somente SMS',
    ONLY_EMAIL_ENGAGEMENT: 'somente email',
    HAS_ANSWER: 'com resposta',
    COUNTERPROPOSAL_UP_TO_20: 'contraproposta (até 20%)',
    COUNTERPROPOSAL_OVER_20: 'contraproposta (+20%)',
    ONLY_VISUALIZED: 'somente visualizados',
    PENDING: 'pendentes',
    UNSETTLED_WITH_MESSAGES: 'perdidos com mensagem',
    NO_DOCUMENT_TERM: 'sem termo',
    PENDING_TO_SEND_SIGNING: 'pendente de envio',
    DOCUMENT_WAITING_SIGNATURES: 'aguardando assinatura',
    DOCUMENT_SIGNED: 'termo assinado',
    NAMESAKE: 'homônimos',
    NO_UPPER_RANGE: 'sem alçada máxima'
  },
  expirationDate: {
    dateTime: 'fim da negociação'
  },
  disputeTag: {
    code: 'Etiqueta da disputa'
  },
  courtHearingDate: {
    dateTime: 'data da audiência'
  },
  disputeClassification: {
    name: 'classificação'
  },
  disputeClassificationDetail: {
    name: 'subclassificação'
  },
  disputeSubClassification: {
    name: 'subclassificação'
  },
  disputeOrg: {
    name: 'comarca / subseção',
    state: 'estado',
    city: 'cidade',
    enum: 'órgão (tj, procon etc.)'
  },
  disputeObjectAttribute: {
    value: 'valor do processo'
  },
  email: {
    address: 'email'
  },
  phone: {
    mobileNumber: 'celular',
    landLineNumber: 'telefone',
    number: 'telefone/celular'
  },
  oab: {
    stateNumber: 'oab',
    number: 'OAB'
  },
  person: {
    name: 'nome',
    alias: 'nome fantasia',
    naturalDocumentNumber: 'cpf',
    legalDocumentNumber: 'cnpj',
    documentNumber: 'CPF'
  },
  attributeBoundary: {
    boundary: 'valor limite| alçada máxima'
  },
  recipients: {
    LAWYER: 'advogado',
    PARTY: 'autor'
  },
  strategyTypes: {
    PAYMENT: 'indenizatorio de valor',
    PROCURADORIA: 'procuradoria',
    PROCON_CONSUMIDOR: 'procon/consumidor',
    RECOVERY: 'cobrança',
    OBLIGATION: 'obrigação de fazer',
    DISCOUNT: 'desconto',
    MULTI_PARTY_NEGOTIATION: 'Negociação com multiplas partes',
    LEGAL_MKT: 'Marketing jurídico',
    COMMUNICATION: 'Comunicação'
  },
  occurrence: {
    type: {
      ENRICHMENT: 'enriquecimento',
      COMMUNICATION: 'comunicação',
      LOG: 'registro',
      INTERACTION: 'interação',
      ENGAGEMENT: 'sem resposta',
      INTERACTIONS: 'interações',
      PENDING: 'pendente',
      IMPORTED: 'importada',
      ACCEPTED: 'acordo s/ dados bancários',
      PAUSED: 'pausada',
      ENRICHED: 'enriquecida',
      CHECKOUT: 'acordo',
      REFUSED: 'recusada',
      SETTLED: 'ganho',
      UNSETTLED: 'perdido',
      RUNNING: 'em negociação',
      EXPIRED: 'expirado',
      FAVORITE: 'Aguardar análise da empresa',
      DISFAVOR: 'Desmarcar aguardar análise da empresa',
      PRE_NEGOTIATION: 'pre-negociação',
      CANCELED: 'cancelada'
    },
    interaction: {
      message: {
        status: {
          WAITING: {
            preposition: 'para',
            label: 'agendada'
          },
          PROCESSED: {
            preposition: 'por',
            label: 'recebida'
          },
          PROCESSED_BY_USER: {
            preposition: 'em',
            label: 'recebida pelo usuário'
          },
          FAILED: {
            preposition: 'para',
            label: 'falha no envio'
          },
          CANCELED: {
            preposition: 'para',
            label: 'envio cancelado'
          },
          RETRYING: {
            preposition: 'para',
            label: 'reenviando'
          },
          WAITING_TO_RESPONSE: {
            preposition: 'de',
            label: 'aguardando resposta'
          }
        }
      }
    }
  },
  profile: {
    NEGOTIATOR: 'negociador',
    ADMINISTRATOR: 'administrador',
    REVISOR: 'revisor'
  },
  action: {
    CANCEL: 'cancelar negociação',
    RESTART_ENGAGEMENT: 'reiniciar',
    'RESTART-ENGAGEMENT': 'reiniciar disputa',
    'RESEND-MESSAGES': 'reenviar mensagens automáticas',
    RESEND_MESSAGE: 'reenviar mensagens',
    SEND_MESSAGE: 'enviar mensagens',
    SETTLED: 'ganhar',
    UNSETTLED: 'perder',
    'SEND-UNSETTLED': 'perder',
    'SEND-COUNTERPROPOSAL': 'perder',
    SEND_COUNTERPROPOSAL: 'perder',
    DELETE: 'excluir',
    PAUSED: 'pausar',
    RESUME: 'despausar',
    FAVORITE: 'Aguardar análise da empresa',
    DISFAVOR: 'Desmarcar aguardar análise da empresa',
    ENRICH: 'enriquecer',
    ENRICH_DISPUTE: 'enriquecer',
    CHANGE_NEGOTIATOR: 'negociadores',
    MUDAR_REVISOR: 'revisores',
    CHANGE_CAMPAIGN: 'alterar campanha',
    CANCEL_MESSAGES: 'cancelar mensagens agendadas',
    'CANCEL-MESSAGES': 'cancelar mensagens agendadas',
    INCREASE_REQUEST: 'solicitar majoração',
    CHANGE_STRATEGY: 'estratégia',
    CHANGE_EXPIRATION_DATE: 'data limite',
    RENEGOTIATE: 'retornar para negociação',
    'EDIT-NEGOTIATORS': 'editar negociadores',
    DROP_LAWSUIT: 'cancelar negociação',
    START_NEGOTIATION: 'inicar negociação',
    SEND_BILK_MESSAGE: 'Compor mensagem em massa',
    UPDATE_ENGAGEMENT_OPTIONS: 'Alterar engajamento',
    ADD_TAGS_INCLUSIVE: 'adicionar etiquetas',
    REM_TAGS_INCLUSIVE: 'remover etiquetas',
    SCHEDULE_CALL: 'Realizar ligações',
    UNSCHEDULE_CALL: 'Cancelar ligações',
    tooltip: {
      BULK_COMPOSE_MESSAGE: 'Compor mensagem em massa',
      SETTLE_DISPUTE: 'Aceitar e Ganhar disputa | Aceitar e Ganhar disputas'
    }
  },
  actions: {
    REDIRECT_MANAGEMENT: {
      name: 'ir para gerenciamento'
    },
    REDIRECT_TICKET: {
      name: 'ir para negociação'
    },
    SETTLED: {
      name: 'ganhar | aceitar acordo',
      'feedback-message': 'disputa <b>ganha</b> | proposta aceita'
    },
    UNSETTLED: {
      name: 'perder',
      'feedback-message': 'disputa <b>perdida</b>'
    },
    SET_UNREAD: {
      name: 'marcar como não lido',
      'feedback-message': 'disputa <b>marcada como não lida</b>'
    },
    RESUME: {
      name: 'despausar negociação',
      'feedback-message': 'disputa <b>retomada</b>'
    },
    PAUSED: {
      name: 'pausar negociação',
      'feedback-message': 'disputa <b>pausada</b>'
    },
    MANUAL_COUNTERPROPOSAL: {
      name: 'contraproposta manual',
      'feedback-message': 'contraproposta manual realizada | alçada máxima <b>majorada</b>. A disputa foi movida para <b>proposta aceita</b>'
    },
    RESTART_ENGAGEMENT: {
      name: 'reiniciar disputa',
      'feedback-message': 'disputa <b>reiniciada</b>'
    },
    RESEND_MESSAGES: {
      name: 'reenviar mensagens automáticas',
      'feedback-message': '<b>mensagens automáticas reenviadas</b>'
    },
    CANCEL_MESSAGES: {
      name: 'cancelar mensagens agendadas',
      'feedback-message': '<b>mensagens canceladas</b>'
    },
    INCREASE_REQUEST: {
      name: 'solicitar majoração',
      'feedback-message': '<b>majoração solicitada</b>'
    },
    EDIT_NEGOTIATORS: {
      name: 'trocar negociadores',
      'feedback-message': 'negociadores alterados'
    },
    NEGOTIATORS: {
      name: 'trocar negociadores',
      'feedback-message': 'negociadores alterados'
    },
    EDIT_REVISORES: {
      name: 'trocar revisores',
      'feedback-message': 'revisores alterados'
    },
    REVISORES: {
      name: 'trocar revisores',
      'feedback-message': 'revisores alterados'
    },
    ENRICH: {
      name: 'enriquecer disputa',
      'feedback-message': 'dispute <b>enriquecida</b>'
    },
    RENEGOTIATE: {
      name: 'retornar para negociação',
      'feedback-message': 'disputa <b>retornada para negociação</b>'
    },
    UPLOAD_ATTACHMENT: {
      name: 'enviar anexo',
      'feedback-message': ''
    },
    DROP_LAWSUIT: {
      name: 'cancelar negociação',
      'feedback-message': '<b>cancelar negociação</b>'
    },
    START_NEGOTIATION: {
      name: 'iniciar negociação',
      'feedback-message': '<b>negociação iniciada</b>'
    },
    DELETE_DOCUMENT: {
      name: 'excluír documento',
      'feedback-message': '<b>minuta excluida</b>'
    },
    PRINT_TICKET_MESSAGES: {
      name: 'exportar mensagens'
    },
    PRINT_TICKET_NOTES: {
      name: 'exportar notas'
    },
    PRINT_TICKET_OCCURRENCES: {
      name: 'exportar ocorrências'
    },
    FAVORITE: {
      name: 'Aguardar análise da empresa',
      'feedback-message': '<b>Aguardar análise da empresa</b>'
    },
    DISFAVOR: {
      name: 'Desmarcar aguardar análise da empresa',
      'feedback-message': '<b>Desmarcar aguardar análise da empresa</b>'
    },
    ADD_TAGS_INCLUSIVE: {
      name: 'Alterar tags'
    },
    SUMMARIZED_OCCURRENCES: {
      name: 'Mostrar ocorrências resumidas',
      'feedback-message': '<b>Mostrar detalhes resumidos das ocorrências agrupadas.</b>'
    },
    GROUPED_OCCURRENCES: {
      name: 'Mostrar ocorrências agrupadas',
      'feedback-message': '<b>Mostrar ocorrências agrupadas.</b>'
    },
    MONOCHROME_OCCURRENCES: {
      name: 'Mostrar ocorrências sem cores',
      'feedback-message': '<b>Mostrar ocorrências sem diferênça de cores.</b>'
    },
    COLORFUL_OCCURRENCES: {
      name: 'Mostrar ocorrências coloridas',
      'feedback-message': '<b>Mostrar ocorrências com diferênça de cores.</b>'
    }
  },
  'executed-actions': {
    RESTART_ENGAGEMENT: 'reiniciou',
    FAVORITE: 'aguardou análise da empresa de',
    DISFAVOR: 'desmarcou aguardar análise da empresa de',
    ACCEPT: 'aceitou',
    SETTLED: 'ganhou',
    UNSETTLED: 'perdeu',
    DELETE: 'excluiu',
    PAUSED: 'pausou',
    RESUME: 'despausou',
    CHANGE_NEGOTIATOR: 'mudou negociadores de',
    CHANGE_STRATEGY: 'mudou estratégia de',
    CHANGE_CAMPAIGN: 'alterou campanha de',
    CANCEL_MESSAGES: 'cancelou mensagens agendadas de',
    CHANGE_EXPIRATION_DATE: 'mudou data limite de',
    RESEND_MESSAGE: 'reenvio mensagens de',
    ENRICH_DISPUTE: 'enriqueceu',
    START_NEGOTIATION: 'iniciou negociação de',
    SEND_MESSAGE: 'enviou mensagens em',
    CHANGE_CONTACT_SETTINGS: 'mudou configurações de contato de',
    CANCEL: 'cancelou negociação de ',
    UPDATE_ENGAGEMENT_OPTIONS: 'alterou engajamento de',
    ADD_TAGS_INCLUSIVE: 'adicionou etiquetas em',
    REM_TAGS_INCLUSIVE: 'removeu etiquetas em',
    ADD_TAGS_EXCLUSIVE: 'adicionou etiquetas exclusivas em',
    REM_TAGS_EXCLUSIVE: 'removeu etiquetas exclusivas em',
    SCHEDULE_DISPUTE_PHONE_CALLS: 'agendou ligações em',
    UNSCHEDULE_DISPUTE_PHONE_CALLS: 'cancelou ligações em',
    MUDAR_REVISOR: 'mudou revisores de'
  },
  'interaction-types': {
    NPS: {
      icon: 'nps-star-active',
      message: 'avaliação NPS recebida'
    },
    PHONE_CALL: {
      icon: 'tts',
      message: 'Ligação feita'
    },
    COMMUNICATION: {
      message: ''
    },
    ATTACHMENT: {
      icon: 'attachment',
      message: 'Novo anexo recebido'
    },
    CLICK: {
      icon: 'click',
      message: 'Abriu um link do email'
    },
    VISUALIZATION: {
      icon: 'eye',
      message: 'visualização no email'
    },
    MANUAL_PROPOSAL: {
      icon: 'exchange',
      message: 'Fez uma proposta'
    },
    MANUAL_COUNTERPROPOSAL: {
      icon: 'exchange',
      message: 'Fez uma contraproposta'
    },
    NEGOTIATOR_ACCESS: {
      icon: 'justto',
      message: 'Acesso no portal'
    },
    NEGOTIATOR_PROPOSAL: {
      icon: 'justto',
      message: 'Nova proposta no portal'
    },
    NEGOTIATOR_COUNTERPROSAL: {
      icon: 'justto',
      message: 'Nova contraproposta no portal'
    },
    NEGOTIATOR_CHECKOUT: {
      icon: 'justto',
      message: 'Aceitou proposta no portal'
    },
    NEGOTIATOR_ACCEPTED: {
      icon: 'justto',
      message: 'Informou dados bancários'
    },
    CANCELED: {
      icon: 'rejected-red',
      message: 'Negociação cancelada.'
    },
    INCREASE_REQUEST: {
      icon: 'justto',
      message: 'Solicitação de majoração.'
    }
  },
  roles: {
    PARTY: {
      RESPONDENT: 'empresa | réu',
      CLAIMANT: 'réu | autor',
      UNKNOWN: 'parte desconhecida'
    },
    LAWYER: {
      RESPONDENT: 'advogado da empresa | advogado do réu',
      CLAIMANT: 'advogado do réu | advogado do autor',
      UNKNOWN: 'advogado desconhecido'
    },
    NEGOTIATOR: {
      RESPONDENT: 'negociador',
      CLAIMANT: 'negociador',
      UNKNOWN: 'negociador desconhecido'
    },
    REVISOR: {
      RESPONDENT: 'revisor',
      CLAIMANT: 'revisor',
      UNKNOWN: 'revisor desconhecido'
    },
    OWN_CAUSE: {
      RESPONDENT: 'advogado em causa própria',
      CLAIMANT: 'advogado em causa própria',
      UNKNOWN: 'causa própria'
    },
    UNKNOWN: {
      RESPONDENT: 'função indefinida',
      CLAIMANT: 'função indefinida',
      UNKNOWN: 'função indefinida'
    }
  },
  'ticket-labels': {
    upperRange: 'alçada máxima',
    courtHearingDate: 'data de audiência',
    importedDate: 'data de importação',
    campaignName: 'campanha',
    code: 'número do processo',
    strategy: 'estratégia',
    strategyId: 'estratégia',
    status: 'status',
    expireDate: 'fim da negociação',
    materialDamageValue: 'dano material',
    moralDamageValue: 'dano moral',
    provisionedValue: 'valor provisionado',
    requestedValue: 'valor do processo',
    configurations: 'configurações',
    description: 'descrição',
    classification: 'classificação',
    internalId: 'código interno',
    skipEnrichment: 'enriquecer automaticamente na importação',
    denySavingDeposit: 'somente depósito em conta-corrente',
    businessHoursEngagement: 'mensagens somente em horário comercial',
    contactParty: 'contatar autor',
    alwaysContactParty: 'sempre',
    neverContactParty: 'nunca',
    contactPartyWhenNoLawyer: 'somente quando não houver advogado constituído',
    contactPartyWhenInvalidLawyer: 'somente quando não for possível contatar o advogado existente',
    contactPartyWhenInvalidLawyerOrNoLawyer: 'Quando não houver advogado ou não for possível contatar o advogado existente',
    denyPixDeposit: 'Bloquear depósito via Pix',
    origem: 'Origem',
    protocolo: 'Protocolo',
    tipo: 'Tipo',
    uf: 'UF',
    state: 'Estado',
    municipio: 'Município',
    dataAbertura: 'Data de Abertura',
    dataResposta: 'Data da Resposta'
  },
  boolean: 'sim | não',
  'ticket-status': {
    CHECKOUT: 'aceita',
    ACCEPTED: 'sem dados bancarios',
    ENGAGEMENT: 'sem resposta da parte',
    ENRICHED: 'enriquecida',
    EXPIRED: 'expirada',
    IMPORTED: 'importada',
    PENDING: 'pendente',
    REFUSED: 'com proposta recusada',
    RUNNING: 'em negociação',
    SETTLED: 'ganha',
    UNSETTLED: 'perdida',
    PRE_NEGOTIATION: 'pré negociação',
    PAUSED: '(pausada) | ',
    CANCELED: 'cancelada'
  },
  'tickets-tabs': {
    'pre-negotiation': 'pré negociação',
    engagement: 'sem resposta',
    running: 'em negociação',
    accepted: 'proposta aceita',
    finished: 'finalizados'
  },
  'configurations-tabs': {
    'workspace-data': 'Dados da conta',
    team: 'Equipe',
    'workspaces-list': 'Workspaces',
    'features-and-modules': 'Funcionalidades e módulos'
  },
  configurations: {
    value: 'gratis | cobrado',
    PRE_NEGOTIATION: {
      label: 'pré negociação',
      icon: 'lighthouse',
      help: 'Posso ativar a pré negociação?\n\nQuanto será cobrado pelo uso desta funcionalidade?\n'
    },
    BAD_FAITH_LITIGANT: {
      label: 'detecção de ofensores',
      icon: 'people-documentation',
      help: ''
    },
    AUTOMATIC_MESSAGES: {
      label: 'automação de mensagens',
      icon: 'automatic-massage'
    },
    DRAFT_MANAGEMENT: {
      label: 'gestão de minutas',
      icon: 'protocol',
      help: ''
    },
    COMMUNICATION_BLOCK_LIST: {
      label: 'lista de bloqueio de endereços',
      icon: 'locked-board',
      help: ''
    },
    API_INTEGRATION: {
      label: 'integração em API para importação',
      icon: 'power-plug',
      help: ''
    },
    CUSTOMIZED_CONFIGURATIONS: {
      label: 'sua empresa/escritório como ODR',
      icon: 'customized-configurations',
      help: ''
    },
    NET_PROMOTER_SCORE: {
      label: 'net promoter score',
      icon: 'net-promoter-score-config',
      help: ''
    },
    CUSTOMIZE_ODR_ADDRESS: {
      label: 'ODR',
      icon: 'customized-configurations',
      help: ''
    },
    CLOSING_REASONS: {
      label: 'Motivos de encerramento',
      icon: 'negative-hand',
      help: ''
    },
    DRAFT_NOTIFICATIONS: {
      label: 'Notificar minutagem',
      icon: 'accepted'
    },
    property: {
      NOTIFY_UNANSWERED_EMAIL: {
        title: 'Notificar parte via e-mail após 36 horas sem resposta.'
      },
      SEND_SCAM_CLAIM_REPLY: {
        title: 'Responder a parte informando legitimidade do contato'
      },
      NOTIFY_UNANSWERED_WHATSAPP: {
        title: 'Notificar parte após 23 horas sem resposta no WhatsApp.'
      },
      NOTIFY_OUT_OF_BUSINESS_HOURS: {
        title: 'Notificar parte sobre o horário de atendimento.'
      },
      SEND_COUNTEROFFER: {
        title: 'Registrar contraproposta automaticamente.'
      },
      SEND_DISPUTE_INFO_REPLY: {
        title: 'Responder parte com informações sobre a disputa.'
      },
      DISPUTE_UNDER_ANALYSIS_REPLY: {
        title: 'Resposta automática às disputas em análise pela empresa.'
      },
      SEND_NPS_SURVEY: {
        title: 'Enviar formulário para avalição de NPS.'
      },
      NOTIFY_DISPUTE_UNDER_ANALYSIS: {
        title: 'Responder automaticamente disputas em analise pela empresa.'
      },
      NOTIFY_DISPUTE_NEGOTATION_PAUSED: {
        title: 'Resposta automática às disputas suspensas(pausadas).'
      },
      NOTIFY_DISPUTE_NEGOTIATION_DONE: {
        title: 'Resposta automática às disputas encerradas.'
      },
      NOTIFY_UNANSWERED_DISPUTE: {
        title: 'Envio de e-mail para disputas sem resposta.'
      }
    },
    authorization: {
      type: {
        API_KEY: 'Autenticação por Chave da API',
        BEARER_TOKEN: 'Autenticação por Token',
        BASIC_AUTH: 'Autenticação Básica'
      }
    }
  },
  fields: {
    claimantParty: 'Réu | Parte contrária',
    claimantParties: 'Réus | Partes contrárias',
    claimantLawyer: 'Advogado do Réu | Advogado da parte',
    claimantLawyers: 'Advogados do Réu | Advogados da parte',
    dispute: 'disputa',
    respondentParty: 'Empresa | Réu',
    respondentParties: 'Empresas | Réus',
    respondentLawyer: 'Advogado da Empresa | Advogado do Réu',
    respondentLawyers: 'Advogados da Empresa | Advogados do Réu',
    respondentNegotiator: 'Negociador',
    impartialParty: 'Arbitro'
  },
  NEGOTIATOR_REJECTED: 'negada de',
  NEGOTIATOR_PROPOSAL: 'feita de',
  NEGOTIATOR_ACCEPTED: 'aceita de',
  el: {
    pagination: {
      pagesize: ' por página',
      goto: 'Ir para',
      pageClassifier: '',
      total: 'Total de disputas: {total}'
    }
  },
  import: {
    label: {
      'business-day': 'Dia útil | Dias úteis',
      'after-the-protocol': 'Após o protocolo | Após os protocolos'
    },
    status: {
      IN_QUEUE: 'falha na importação',
      READY: 'em processamento',
      PROCESSING: 'em processamento',
      PROCESSED: 'importação cancelada',
      FINISHED: 'planilha importada',
      ERROR: 'falha na importação'
    }
  },
  'bank-account': {
    linkDisputeBankAccounts: 'vinculada',
    unlinkDisputeBankAccounts: 'desvinculada',
    type: {
      SAVING: 'Poupança',
      CHECKING: 'Corrente',
      PIX: 'Pix'
    }
  },
  'member-status': {
    blocked: 'Conta bloqueada',
    'activation-pending': 'Aguardando ativação',
    'password-reset': 'Senha resetada',
    active: 'Ativo',
    inactive: 'Conta inativa'
  },
  tab: {
    0: 'entrada de disputas',
    1: 'sem resposta',
    2: 'em negociação',
    3: 'proposta aceita',
    4: 'finalizados',
    9: 'todas'
  },
  panel: {
    '-1': 'Painel administrativo',
    0: 'Painel administrativo',
    1: 'Equipes',
    2: 'Usuários',
    3: 'Estratégias',
    4: 'Minutas',
    5: 'Cobrança',
    6: 'Workapces',
    7: 'WhatsApp',
    8: 'Busca global'
  },
  DISPUTE_CODE: 'Nº Processo',
  CAMPAIGN: 'Campanha',
  STRATEGY: 'Estratégia',
  CLASSIFICATION: 'Classificação',
  CLASSIFICATION_DETAIL: 'Detalhes da classificação',
  DISPUTE_ORG: 'Vara',
  EXPIRATION_DATE: 'Data de expiração',
  DESCRIPTION: 'Descrição',
  PAUSED: 'Pausado',
  FAVORITE: 'Favorito',
  VISUALIZED: 'Visualizado',
  STATUS: 'Status',
  FIRST_INTERACTION_DATE: 'Data primeira interação',
  LAST_INTERACTION_DATE: 'Data última interação',
  UPPER_RANGE: 'Valor limite | Alçada máxima',
  UPPER_RANGE_WITH_ARTICLE: 'o valor limite | a alçada máxima',
  REQUESTED_VALUE: 'Valor do processo',
  PROPOSAL_VALUE: 'Valor proposto',
  COUNTER_PROPOSAL_VALUE: 'Contraproposta',
  OWNER_PROPOSAL: 'Proposta por',
  OWNER_COUNTER_PROPOSAL: 'Contraproposta por',
  HAS_DOCUMENT: 'Possui minuta',
  SIGNED_DOCUMENT_STATUS: 'Status de assinatura',
  LAST_NEGOTIATOR_ACCESS_DATE: 'Último acesso do autor na plataforma JUSTTO',
  LAST_VISUALIZATION_BY_NEGOTIATOR: 'Último acesso do negociador na disputa',
  IMPORT_DATE: 'Data de importação',
  CONCLUSION_DESCRIPTION: 'Descrição da conclusão',
  CONCLUSION_REASONS: 'Motivo da perda',
  LAST_OFFER_VALUE: 'Valor do acordo',
  PARTY_NAMES: 'Nome das partes',
  PARTY_DOCUMENTS: 'Documento das partes',
  LAWYER_PARTY_NAMES: 'Nome dos advogados da parte',
  LAWYER_PARTY_DOCUMENTS: 'Documento dos advogados da parte',
  RESPONDENT_NAMES: 'Réu',
  NEGOTIATOR_NAMES: 'Negociador',
  LAST_SENT_MESSAGE: 'Última mensagem enviada',
  LAST_RECEIVED_MESSAGE: 'Última mensagem recebida',
  LAST_NOTE: 'Última nota',
  CONCLUSION_DATE: 'Data de conclusão',
  PARTY_RESPONDENT: 'Empresa | Réu',
  LAWYER_RESPONDENT: 'Advogado da Empresa | Advogado do Réu',
  ARTICLE: 'o | a',
  AVAILABLE: 'Disponível | Não disponível',
  I_AM_AVAILABLE: 'Estou disponível | Não estou disponível',
  ENABLED: 'Habilitado | Desabilitado',
  workspace: {
    READY: 'ativo',
    CREATING: 'em criação',
    DISABLED: 'inativo'
  },
  dashboard: {
    NPS_DETRACTOR_PERCENTAGE: 'detrator',
    NPS_NEUTRAL_PERCENTAGE: 'neutro',
    NPS_PROMOTER_PERCENTAGE: 'promotor',
    UPPER_RANGE_AVG: 'alçada média',
    SETTLED_DEALS_AVG: 'média dos acordos',
    SAVINGS_TOTAL: 'economia',
    SAVINGS_PERCENTAGE: '%'
  },
  error: {
    ALREADY_EXISTS: 'Este e-mail já está cadastrado como membro de sua equipe. De qualquer forma, enviaremos um e-mail informando sua intenção de adiciona-lo na equipe.',
    INVALID_CREDENTIALS: 'Senha atual incorreta',
    NO_REQUIRED_PARAMETERS: 'Parâmetros obrigatórios não informados',
    EXCEEDED_ROWS_LIMIT: 'Excede o limite de linhas configurado no servidor para exportar XLSX.',
    EMPTY_RESULT: 'Nenhum resultado disponível para a consulta solicitada',
    FAIL_TO_PARSE_DISPUTES: 'Falha ao realizar o PARSER de disputas',
    FAIL_TO_WRITE_FILE: 'Falha ao criar arquivo',
    REGISTER_NOT_FOUND: 'Registro não encontrado',
    NO_AVAILABLE_NEGOTIATOR: 'Sem negociadores disponível na equipe',
    FAIL_TO_DELETE: 'Falha ao remover registro',
    INVALID_VALUE: 'Valor informado inválido',
    NOT_ALLOWED: 'Operação não permitida',
    AUTHORIZATION: '',
    GENERIC_VALIDATION: '',
    DUPLICATED_VALIDATION: '',
    PATTERN_VALIDATION: ''
  },
  threshold: {
    QUANTITY: 'Por contagem de disputas',
    AVERAGE: 'Por percentual acima da média'
  },
  billing: {
    contract: {
      status: {
        ACTIVE: 'Ativo',
        TRIAL: 'Trial',
        INACTIVE: 'Inativo',
        SCHEDULED: 'Programado'
      }
    }
  },
  negotiation: {
    ticket: {
      omnichannel: {
        editor: {
          tab: {
            message: 'Mensagem | Mensagens',
            note: 'Nota | Notas',
            occurrence: 'Ocorrência | Ocorrências'
          }
        },
        occurrence: {
          summary: {
            'count-email': 'e-mail foi agendado para | e-mails foram agendados para',
            'count-whatsapp': 'mensagem de whatsapp foi agendada para | mensagens de whatsspp foram agendadas para',
            'count-sms': 'mensagem de texto foi agendada para | mensagens de texto foram agendadas para'
          },
          COMMUNICATION: {
            WHATSAPP: {
              AUDIO: 'Áudio recebido pelo WhatsApp',
              IMAGE: 'Imagem recebida pelo WhatsApp',
              VIDEO: 'Vídeo recebido pelo WhatsApp',
              FILE: 'Arquivo recebido pelo WhatsApp'
            }
          }
        }
      },
      recipient: {
        'message-type': {
          whatsapp: 'whatsapp',
          negotiator_message: 'portal de negociação',
          negotiation: 'portal de negociação',
          email: 'e-mail'
        }
      }
    }
  },
  events: {
    NEGOTIATION_EVENTS_EDITOR_FOCUS: 'Foca no editor de mesnagens/notas.',
    GLOBAL_EVENTS_SEARCH_FOCUS: 'Foca na barra de pesquisa de disputas.',
    NEGOTIATION_EVENTS_TICKET_UP: 'Volta para o ticket anterior, da tela de negociação.',
    NEGOTIATION_EVENTS_TICKET_DOWN: 'Avança para o próximo ticket, da tela de negociação.',
    NEGOTIATION_EVENTS_TICKET_PREVIOUS_TAB: 'Volta para a aba anterior, da listagem de tickets.',
    NEGOTIATION_EVENTS_TICKET_NEXT_TAB: 'Avança para a próxima aba, da listagem de tickets.',
    SHOW_SHORTCUTS_HELP_1: 'Abre esta janela de ajuda com os atalhos.',
    SHOW_SHORTCUTS_HELP_2: 'Abre esta janela de ajuda com os atalhos.'
  },
  dialog: {
    title: {
      'my-data': 'Meus dados',
      CHANGE_REVIEWRES: 'Alterar revisores'
    }
  },
  reports: {
    UNKNOWN: 'Desabilitado | Desabilitada',
    DAILY: 'Diário | Diária',
    WEEKLY: 'Semanal',
    FORTNIGHT: 'Quinzenal',
    MONTHLY: 'Mensal',
    INACTIVE: 'Desabilitado | Desabilitada'
  },
  labels: {
    dispute: 'disputa | disputas'
  },
  recomendations: {
    COUNTEROFFER: {
      option: 'Enviar contraproposta',
      title: 'Gostaria de enviar uma contraproposta?',
      buttons: {
        primary: 'ENVIAR',
        secondary: 'EDITAR RESPOSTA'
      }
    },
    NEW_PARTY: {
      option: 'Cadastrar nova parte',
      title: 'Gostaria de cadastrar uma nova parte?',
      buttons: {
        primary: 'ENVIAR',
        secondary: ''
      }
    },
    NEW_CONTACT: {
      option: 'Adicionar novo contato',
      title: 'Gostaria de adicionar um novo contato?',
      buttons: {
        primary: 'ADICIONAR',
        secondary: ''
      }
    },
    DISPUTE_MESSAGE: {
      option: 'Responder mensagem',
      title: 'Gostaria de responder de forma rápida?',
      buttons: {
        primary: 'ENVIAR',
        secondary: 'EDITAR RESPOSTA'
      }
    }
  },
  call: {
    status: {
      ENQUEUED: 'Na fila',
      WAITING_DIALER: 'Buscando discador',
      WAITING_DIALER_DETAIL: 'Reservando discador',
      WAITING_NEW_CALL: 'Processando chamada',
      RECEIVING_CALL: 'Entrando na chamada',
      ACTIVE_CALL: 'Em chamada',
      COMPLETED_CALL: 'Chamada encerrada'
    },
    interaction: {
      status: {
        DIFFERENT_OWNER: 'Contato de outra pessoa',
        NONEXISTENT: 'Contato não existe'
      }
    },
    scheduled: {
      label: 'Ligações agendadas',
      available: 'Ligar automaticamente | Não ligar automaticamente'
    }
  },
  administrativeProceedingCode: {
    code: 'nº do processo administrativo'
  },
  blocked: {
    email: {
      UNKNOW: 'Este contato foi bloqueado pelo usuário.',
      COMMUNICATION_OPT_OUT: 'Usuário optou por não receber mensagens nesse e-mail.',
      LAW_FIRM_DOMAIN: 'Contato com domínio de e-mail bloqueado para recebimento de mensagem.',
      WORKSPACE_BLOCK_LIST: 'Contato bloqueado pela configuração na workspace.',
      PERSON_DOCUMENT: 'Usuário optou por não receber mensagens da plataforma.',
      LAWSUIT: 'Usuário optou por não receber mensagens nesse processo.',
      DEFENDANT_NAME: 'Usuário optou por não receber mensagens deste réu.'
    },
    phone: {
      UNKNOW: 'Este contato foi bloqueado pelo usuário.',
      COMMUNICATION_OPT_OUT: 'Usuário optou por não receber mensagens nesse telefone.',
      LAW_FIRM_DOMAIN: 'Contato com telefone bloqueado para recebimento de mensagem.',
      WORKSPACE_BLOCK_LIST: 'Contato bloqueado pela configuração na workspace.',
      PERSON_DOCUMENT: 'Usuário optou por não receber mensagens da plataforma.',
      LAWSUIT: 'Usuário optou por não receber mensagens nesse processo.',
      DEFENDANT_NAME: 'Usuário optou por não receber mensagens deste réu.'
    }
  },
  document: {
    signature: {
      status: {
        CREATED: {
          text: 'Minuta criada',
          icon: 'clock'
        },
        PENDING: {
          text: 'Assinatura pendente',
          icon: 'clock-alert'
        },
        COMPLETED: {
          text: 'Assinado',
          icon: 'success'
        },
        REFUSED: {
          text: 'Recusado',
          icon: 'rejected'
        },
        UNKNOWN: {
          text: 'Aguardando Assinatura',
          icon: 'clock-alert'
        },
        WAITING_APPROVAL: {
          text: 'Aguardando aprovação do negociador',
          icon: 'clock-alert'
        }
      }
    }
  },
  'integration-types': {
    PROJURIS_SOAP: 'Projuris Empresas',
    FINCH: 'Webservices da finch',
    JUSTTO_WEBHOOK: 'Webhook padrão do Projuris Acordos'
  },
  'increase-status': {
    WAITING: 'Aguardando análise',
    APPROVED: 'Aprovado',
    REJECTED: 'Rejeitado',
    CANCELED: 'Cancelado'
  },
  INDENIZATORIO: {
    dispute: {
      code: 'Processo',
      externalId: 'Código interno',
      claimantParty: 'Parte contrária',
      respondentParty: 'Réu',
      claimantLawyer: 'Advogado da parte',
      upperRange: 'Alçada máxima',
      counterOfferValue: 'Proposta da parte',
      expirationDate: 'Fim da negociação',
      roles: {
        PARTY: {
          RESPONDENT: 'réu',
          CLAIMANT: 'autor',
          UNKNOWN: 'parte desconhecida'
        },
        LAWYER: {
          RESPONDENT: 'advogado do réu',
          CLAIMANT: 'advogado do autor',
          UNKNOWN: 'advogado desconhecido'
        },
        NEGOTIATOR: {
          RESPONDENT: 'negociador',
          CLAIMANT: 'negociador',
          UNKNOWN: 'negociador desconhecido'
        },
        REVISOR: {
          RESPONDENT: 'revisor',
          CLAIMANT: 'revisor',
          UNKNOWN: 'revisor desconhecido'
        },
        OWN_CAUSE: {
          RESPONDENT: 'advogado em causa própria',
          CLAIMANT: 'advogado em causa própria',
          UNKNOWN: 'causa própria'
        },
        UNKNOWN: {
          RESPONDENT: 'função indefinida',
          CLAIMANT: 'função indefinida',
          UNKNOWN: 'função indefinida'
        }
      },
      fields: {
        claimantParty: 'Parte contrária',
        claimantParties: 'Partes contrárias',
        claimantLawyer: 'Advogado da parte',
        claimantLawyers: 'Advogados da parte',
        claimantProposal: 'Proposta da Parte',
        dispute: 'disputa',
        respondentParty: 'Réu',
        respondentParties: 'Réus',
        respondentLawyer: 'Advogado do Réu',
        respondentLawyers: 'Advogados do Réu',
        respondentNegotiator: 'Negociador',
        impartialParty: 'Arbitro'
      }
    },
    workspace: {
      description: 'Indenizatório',
      icon: 'coins'
    },
    import: {
      tags: {
        administrativeProceedingCode: {
          code: 'nº do processo administrativo'
        },
        attributeBoundary: {
          boundary: 'alçada máxima'
        },
        courtHearingDate: {
          dateTime: 'data da audiência'
        },
        dispute: {
          code: 'nº do processo',
          description: 'descrição',
          externalId: 'código interno da disputa',
          mappedCampaign: 'nome da campanha',
          mappedLinkNames: 'nomes de documentos externos',
          mappedLinks: 'links de documentos externos',
          mappedNote: 'notas da disputa',
          mappedProposalValue: 'valor da primeira proposta',
          mappedStrategy: 'nome da estratégia',
          materialDamage: 'valor dano material',
          moralDamage: 'valor dano moral',
          negotiatorEmail: 'email do negociador',
          provisionedValue: 'valor provisionado',
          revisorEmail: 'email do revisor',
          proposalRule: 'régua de proposta'
        },
        disputeClassification: {
          name: 'classificação'
        },
        disputeClassificationDetail: {
          name: 'subclassificação'
        },
        disputeObjectAttribute: {
          value: 'valor do processo'
        },
        disputeOrg: {
          name: 'comarca / subseção',
          state: 'estado',
          city: 'cidade',
          enum: 'órgão (tj, procon etc.)'
        },
        disputeTag: {
          code: 'Etiqueta da disputa'
        },
        email: {
          address: 'email'
        },
        expirationDate: {
          dateTime: 'fim da negociação'
        },
        oab: {
          stateNumber: 'oab',
          number: 'OAB'
        },
        person: {
          name: 'nome',
          alias: 'nome fantasia',
          naturalDocumentNumber: 'cpf',
          legalDocumentNumber: 'cnpj',
          documentNumber: 'CPF'
        },
        phone: {
          mobileNumber: 'celular',
          landLineNumber: 'telefone',
          number: 'telefone/celular'
        }
      }
    },
    management: {
      tabs: {
        0: 'Pré-Negociação',
        1: 'Sem resposta',
        2: 'Em negociação',
        3: 'Proposta aceita',
        4: 'Finalizados',
        9: 'Todas as disputas'
      }
    },
    occurrence: {
      type: {
        ENRICHMENT: 'enriquecimento',
        COMMUNICATION: 'comunicação',
        LOG: 'registro',
        INTERACTION: 'interação',
        ENGAGEMENT: 'sem resposta',
        INTERACTIONS: 'interações',
        PENDING: 'pendente',
        IMPORTED: 'importada',
        ACCEPTED: 'acordo s/ dados bancários',
        PAUSED: 'pausada',
        ENRICHED: 'enriquecida',
        CHECKOUT: 'acordo',
        REFUSED: 'recusada',
        SETTLED: 'ganho',
        UNSETTLED: 'perdido',
        RUNNING: 'em negociação',
        EXPIRED: 'expirado',
        FAVORITE: 'Aguardar análise da empresa',
        DISFAVOR: 'Desmarcar aguardar análise da empresa',
        PRE_NEGOTIATION: 'pre-negociação',
        CANCELED: 'cancelada'
      }
    }
  },
  RECOVERY: {
    dispute: {
      code: 'Processo',
      externalId: 'Código interno',
      claimantParty: 'Réu',
      respondentParty: 'Empresa',
      claimantLawyer: 'Advogado do Réu',
      upperRange: 'Valor limite',
      counterOfferValue: 'Proposta do réu',
      expirationDate: 'Fim da negociação',
      roles: {
        PARTY: {
          RESPONDENT: 'empresa',
          CLAIMANT: 'réu',
          UNKNOWN: 'parte desconhecida'
        },
        LAWYER: {
          RESPONDENT: 'advogado da empresa',
          CLAIMANT: 'advogado do réu',
          UNKNOWN: 'advogado desconhecido'
        },
        NEGOTIATOR: {
          RESPONDENT: 'negociador',
          CLAIMANT: 'negociador',
          UNKNOWN: 'negociador desconhecido'
        },
        REVISOR: {
          RESPONDENT: 'revisor',
          CLAIMANT: 'revisor',
          UNKNOWN: 'revisor desconhecido'
        },
        OWN_CAUSE: {
          RESPONDENT: 'advogado em causa própria',
          CLAIMANT: 'advogado em causa própria',
          UNKNOWN: 'causa própria'
        },
        UNKNOWN: {
          RESPONDENT: 'função indefinida',
          CLAIMANT: 'função indefinida',
          UNKNOWN: 'função indefinida'
        }
      },
      fields: {
        claimantParty: 'Réu',
        claimantParties: 'Réus',
        claimantLawyer: 'Advogado do Réu',
        claimantLawyers: 'Advogados do Réu',
        claimantProposal: 'Proposta do Réu',
        dispute: 'disputa',
        respondentParty: 'Empresa',
        respondentParties: 'Empresas',
        respondentLawyer: 'Advogado da Empresa',
        respondentLawyers: 'Advogados da Empresa',
        respondentNegotiator: 'Negociador',
        impartialParty: 'Arbitro'
      }
    },
    workspace: {
      description: 'Cobrança',
      icon: 'exchange'
    },
    import: {
      tags: {
        administrativeProceedingCode: {
          code: 'nº do processo administrativo'
        },
        attributeBoundary: {
          boundary: 'valor limite'
        },
        courtHearingDate: {
          dateTime: 'data da audiência'
        },
        dispute: {
          code: 'nº do processo',
          description: 'descrição',
          externalId: 'código interno da disputa',
          mappedCampaign: 'nome da campanha',
          mappedLinkNames: 'nomes de documentos externos',
          mappedLinks: 'links de documentos externos',
          mappedNote: 'notas da disputa',
          mappedProposalValue: 'valor da primeira proposta',
          mappedStrategy: 'nome da estratégia',
          materialDamage: 'valor dano material',
          moralDamage: 'valor dano moral',
          negotiatorEmail: 'email do negociador',
          provisionedValue: 'valor provisionado',
          revisorEmail: 'email do revisor',
          proposalRule: 'régua de proposta'
        },
        disputeClassification: {
          name: 'classificação'
        },
        disputeClassificationDetail: {
          name: 'subclassificação'
        },
        disputeObjectAttribute: {
          value: 'valor do processo'
        },
        disputeOrg: {
          name: 'comarca / subseção',
          state: 'estado',
          city: 'cidade',
          enum: 'órgão (tj, procon etc.)'
        },
        disputeTag: {
          code: 'Etiqueta da disputa'
        },
        email: {
          address: 'email'
        },
        expirationDate: {
          dateTime: 'fim da negociação'
        },
        oab: {
          stateNumber: 'oab',
          number: 'OAB'
        },
        person: {
          name: 'nome',
          alias: 'nome fantasia',
          naturalDocumentNumber: 'cpf',
          legalDocumentNumber: 'cnpj',
          documentNumber: 'CPF'
        },
        phone: {
          mobileNumber: 'celular',
          landLineNumber: 'telefone',
          number: 'telefone/celular'
        }
      }
    },
    management: {
      tabs: {
        0: 'Pré-Negociação',
        1: 'Sem resposta',
        2: 'Em negociação',
        3: 'Proposta aceita',
        4: 'Finalizados',
        9: 'Todas as disputas'
      }
    },
    occurrence: {
      type: {
        ENRICHMENT: 'enriquecimento',
        COMMUNICATION: 'comunicação',
        LOG: 'registro',
        INTERACTION: 'interação',
        ENGAGEMENT: 'sem resposta',
        INTERACTIONS: 'interações',
        PENDING: 'pendente',
        IMPORTED: 'importada',
        ACCEPTED: 'acordo s/ dados bancários',
        PAUSED: 'pausada',
        ENRICHED: 'enriquecida',
        CHECKOUT: 'acordo',
        REFUSED: 'recusada',
        SETTLED: 'ganho',
        UNSETTLED: 'perdido',
        RUNNING: 'em negociação',
        EXPIRED: 'expirado',
        FAVORITE: 'Aguardar análise da empresa',
        DISFAVOR: 'Desmarcar aguardar análise da empresa',
        PRE_NEGOTIATION: 'pre-negociação',
        CANCELED: 'cancelada'
      }
    }
  },
  PROCURADORIA: {
    dispute: {
      code: 'Número da CDA',
      externalId: 'Código interno',
      claimantParty: 'Corresponsável',
      respondentParty: 'Procuradoria',
      claimantLawyer: 'Devedor',
      upperRange: 'Saldo',
      counterOfferValue: 'Proposta do Devedor',
      expirationDate: 'Data limite',
      roles: {
        PARTY: {
          RESPONDENT: 'Procuradoria',
          CLAIMANT: 'Corresponsável',
          UNKNOWN: 'parte desconhecida'
        },
        LAWYER: {
          RESPONDENT: 'advogado da procuradoria',
          CLAIMANT: 'Devedor',
          UNKNOWN: 'advogado desconhecido'
        },
        NEGOTIATOR: {
          RESPONDENT: 'negociador',
          CLAIMANT: 'negociador',
          UNKNOWN: 'negociador desconhecido'
        },
        REVISOR: {
          RESPONDENT: 'revisor',
          CLAIMANT: 'revisor',
          UNKNOWN: 'revisor desconhecido'
        },
        OWN_CAUSE: {
          RESPONDENT: 'advogado em causa própria',
          CLAIMANT: 'Devedor em causa própria',
          UNKNOWN: 'causa própria'
        },
        UNKNOWN: {
          RESPONDENT: 'função indefinida',
          CLAIMANT: 'função indefinida',
          UNKNOWN: 'função indefinida'
        }
      },
      fields: {
        claimantParty: 'Corresponsável',
        claimantParties: 'Corresponsávels',
        claimantLawyer: 'Devedor',
        claimantLawyers: 'Devedores',
        claimantProposal: 'Proposta do Devedor',
        dispute: 'disputa',
        respondentParty: 'Procuradoria',
        respondentParties: 'Procuradorias',
        respondentLawyer: 'Advogado da Procuradoria',
        respondentLawyers: 'Advogados da Procuradoria',
        respondentNegotiator: 'Negociador',
        impartialParty: 'Arbitro'
      }
    },
    workspace: {
      description: 'Procuradoria',
      icon: 'email-cna'
    },
    import: {
      tags: {
        administrativeProceedingCode: {
          code: 'nº do processo administrativo'
        },
        attributeBoundary: {
          boundary: 'saldo'
        },
        courtHearingDate: {
          dateTime: 'data da audiência'
        },
        dispute: {
          code: 'número da CDA',
          description: 'número parcelamento',
          externalId: 'código interno',
          mappedCampaign: 'nome da campanha',
          mappedLinkNames: 'nomes de documentos externos',
          mappedLinks: 'links de documentos externos',
          mappedNote: 'notas da disputa',
          mappedProposalValue: 'saldo',
          mappedStrategy: 'nome da estratégia',
          materialDamage: 'valor dano material',
          moralDamage: 'valor dano moral',
          negotiatorEmail: 'email do negociador',
          provisionedValue: 'valor provisionado',
          revisorEmail: 'email do revisor',
          proposalRule: 'régua de proposta'
        },
        disputeClassification: {
          name: 'classificação'
        },
        disputeClassificationDetail: {
          name: 'subclassificação'
        },
        disputeObjectAttribute: {
          value: 'valor do processo'
        },
        disputeOrg: {
          name: 'comarca / subseção',
          state: 'estado devedor',
          city: 'municipio devedor',
          enum: 'órgão (tj, procon etc.)'
        },
        disputeTag: {
          code: 'Situação Judicial'
        },
        email: {
          address: 'email'
        },
        expirationDate: {
          dateTime: 'data limite'
        },
        oab: {
          stateNumber: 'oab',
          number: 'OAB'
        },
        person: {
          name: 'nome',
          alias: 'nome fantasia',
          naturalDocumentNumber: 'cpf',
          legalDocumentNumber: 'cnpj',
          documentNumber: 'Documento Principal'
        },
        phone: {
          mobileNumber: 'celular',
          landLineNumber: 'telefone',
          number: 'telefone/celular'
        }
      }
    },
    management: {
      tabs: {
        0: 'Pré-Negociação',
        1: 'Sem resposta',
        2: 'Em negociação',
        3: 'Proposta aceita',
        4: 'Finalizados',
        9: 'Todas as disputas'
      }
    },
    occurrence: {
      type: {
        ENRICHMENT: 'enriquecimento',
        COMMUNICATION: 'comunicação',
        LOG: 'registro',
        INTERACTION: 'interação',
        ENGAGEMENT: 'sem resposta',
        INTERACTIONS: 'interações',
        PENDING: 'pendente',
        IMPORTED: 'importada',
        ACCEPTED: 'acordo s/ dados bancários',
        PAUSED: 'pausada',
        ENRICHED: 'enriquecida',
        CHECKOUT: 'acordo',
        REFUSED: 'recusada',
        SETTLED: 'ganho',
        UNSETTLED: 'perdido',
        RUNNING: 'em negociação',
        EXPIRED: 'expirado',
        FAVORITE: 'Aguardar análise da empresa',
        DISFAVOR: 'Desmarcar aguardar análise da empresa',
        PRE_NEGOTIATION: 'pre-negociação',
        CANCELED: 'cancelada'
      }
    }
  },
  PROCON_CONSUMIDOR: {
    dispute: {
      code: 'Protocolo',
      externalId: 'Origem',
      claimantParty: 'Consumidor',
      respondentParty: 'Reclamada',
      claimantLawyer: 'Advogado do consumidor',
      upperRange: 'Alçada máxima',
      counterOfferValue: 'Proposta do consumidor',
      expirationDate: 'Prazo',
      conclusionDate: 'Data resposta',
      requestedValue: 'Valor do processo',
      description: 'Situação',
      firstRespondentName: 'Responsável',
      createdAt: 'Data Criação',
      materialDamage: 'OBF',
      moralDamage: 'Direitos violados',
      origem: 'Origem',
      roles: {
        PARTY: {
          RESPONDENT: 'reclamada',
          CLAIMANT: 'consumidor',
          UNKNOWN: 'parte desconhecida'
        },
        LAWYER: {
          RESPONDENT: 'advogado do reclamada',
          CLAIMANT: 'advogado do consumidor',
          UNKNOWN: 'advogado desconhecido'
        },
        NEGOTIATOR: {
          RESPONDENT: 'negociador',
          CLAIMANT: 'negociador',
          UNKNOWN: 'negociador desconhecido'
        },
        REVISOR: {
          RESPONDENT: 'revisor',
          CLAIMANT: 'revisor',
          UNKNOWN: 'revisor desconhecido'
        },
        OWN_CAUSE: {
          RESPONDENT: 'advogado em causa própria',
          CLAIMANT: 'advogado em causa própria',
          UNKNOWN: 'causa própria'
        },
        UNKNOWN: {
          RESPONDENT: 'função indefinida',
          CLAIMANT: 'função indefinida',
          UNKNOWN: 'função indefinida'
        }
      },
      fields: {
        claimantParty: 'Reclamante',
        claimantParties: 'Reclamantes',
        claimantLawyer: 'Advogado do reclamante',
        claimantLawyers: 'Advogados do reclamante',
        claimantProposal: 'Proposta do reclamante',
        dispute: 'disputa',
        respondentParty: 'Reclamada',
        respondentParties: 'Reclamadas',
        respondentLawyer: 'Advogado da reclamada',
        respondentLawyers: 'Advogados da reclamada',
        respondentNegotiator: 'Negociador',
        impartialParty: 'Arbitro'
      }
    },
    workspace: {
      description: 'Procon',
      icon: 'user-magnifying-glass'
    },
    import: {
      tags: {
        administrativeProceedingCode: {
          code: 'nº do processo administrativo'
        },
        attributeBoundary: {
          boundary: 'saldo'
        },
        courtHearingDate: {
          dateTime: 'data da audiência'
        },
        dispute: {
          code: 'número da CDA',
          description: 'número parcelamento',
          externalId: 'código interno',
          mappedCampaign: 'nome da campanha',
          mappedLinkNames: 'nomes de documentos externos',
          mappedLinks: 'links de documentos externos',
          mappedNote: 'notas da disputa',
          mappedProposalValue: 'saldo',
          mappedStrategy: 'nome da estratégia',
          materialDamage: 'valor dano material',
          moralDamage: 'valor dano moral',
          negotiatorEmail: 'email do negociador',
          provisionedValue: 'valor provisionado',
          revisorEmail: 'email do revisor',
          proposalRule: 'régua de proposta'
        },
        disputeClassification: {
          name: 'classificação'
        },
        disputeClassificationDetail: {
          name: 'subclassificação'
        },
        disputeObjectAttribute: {
          value: 'valor do processo'
        },
        disputeOrg: {
          name: 'comarca / subseção',
          state: 'estado devedor',
          city: 'municipio devedor',
          enum: 'órgão (tj, procon etc.)'
        },
        disputeTag: {
          code: 'Situação Judicial'
        },
        email: {
          address: 'email'
        },
        expirationDate: {
          dateTime: 'data limite'
        },
        oab: {
          stateNumber: 'oab',
          number: 'OAB'
        },
        person: {
          name: 'nome',
          alias: 'nome fantasia',
          naturalDocumentNumber: 'cpf',
          legalDocumentNumber: 'cnpj',
          documentNumber: 'Documento Principal'
        },
        phone: {
          mobileNumber: 'celular',
          landLineNumber: 'telefone',
          number: 'telefone/celular'
        }
      }
    },
    management: {
      tabs: {
        0: 'Capturados',
        1: 'Novos',
        2: 'Em tratamento',
        3: 'Resposta manual',
        4: 'Respondido',
        5: 'Resposta manual',
        9: 'Todas as reclamações'
      }
    },
    occurrence: {
      type: {
        ENRICHMENT: 'enriquecimento',
        COMMUNICATION: 'comunicação',
        LOG: 'registro',
        INTERACTION: 'interação',
        ENGAGEMENT: 'sem resposta',
        INTERACTIONS: 'interações',
        PENDING: 'pendente',
        IMPORTED: 'importada',
        ACCEPTED: 'resposta manual',
        PAUSED: 'pausada',
        ENRICHED: 'enriquecida',
        CHECKOUT: 'acordo',
        REFUSED: 'recusada',
        SETTLED: 'ganho',
        UNSETTLED: 'perdido',
        RUNNING: 'em negociação',
        EXPIRED: 'expirado',
        FAVORITE: 'Aguardar análise da empresa',
        DISFAVOR: 'Desmarcar aguardar análise da empresa',
        PRE_NEGOTIATION: 'pre-negociação',
        CANCELED: 'cancelada'
      }
    }
  }
}

export default ptBr
